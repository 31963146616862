import React from "react";
import { Link } from "gatsby";

import Layout from "../components/Layout";
import Header from "../components/Header";
import Scroll from "../components/Scroll";
import Footer from "../components/Footer";
// import Social from '../components/Social';

import demo1 from "../assets/images/demo-screen-1.png";
import demo2 from "../assets/images/ipad-screen.png";
import astore from "../assets/images/app-store-badge.svg";

const IndexPage = () => (
  <Layout>
    <Header />

    <header className="masthead">
      <div className="container h-100">
        <div className="row h-100">
          <div className="col-lg-7 my-auto">
            <div className="header-content mx-auto">
              <h1 className="mb-5">
                Free to try, simple, daycare charting app for iPhone and iPad.
              </h1>
              <h1 className="mb-5">
                Email or Message a PDF to a parent or guardian.
              </h1>
              <Scroll type="id" element="download">
                <Link to="#download" className="btn btn-outline btn-xl">
                  <strong>Start Now for Free</strong>
                </Link>
              </Scroll>
            </div>
          </div>
          <div className="col-lg-5 my-auto">
            <div className="device-container">
              <div className="device-mockup iphone6_plus portrait white">
                <div className="device">
                  <div className="screen">
                    <img src={demo1} className="img-fluid" alt="" />
                  </div>
                  <div className="button"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>

    <section className="download bg-primary text-center" id="download">
      <div className="container">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <h2 className="section-heading">No More Paper!</h2>

            <p>
              Available on Apple&trade; iOS devices.
              <br></br>
              Download now to get started!
            </p>
            <div className="badges">
              <a className="badge-link" href="https://apps.apple.com/us/app/look-at-me-daycare/id1254621223">
                <img src={astore} alt="Apple store link" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="features" id="features">
      <div className="container">
        <div className="section-heading text-center">
          <h2>FEATURES</h2>
          <p className="text-muted">Make tracking your day easier!</p>
          <hr />
        </div>
        <div className="row">
          <div className="col-lg-8 my-auto">
            <div className="device-container">
              <div className="device-mockup ipad_air_2 landscape silver">
                <div className="device">
                  <div className="screen">
                    <img src={demo2} className="img-fluid" alt="" />
                  </div>
                  <div className="button"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 my-auto">
            <div className="container-fluid">
              <div className="row">
                <div className="feature-item">
                  <i className="icon-screen-smartphone text-primary"></i>
                  <h3>Share info as a PDF</h3>

                  <p className="text-muted">
                    <em>The PDF format is an open standard</em>
                    <br></br>
                    <hr></hr>
                    <br></br>
                    Parents &amp; guardians can download, read, and share that
                    day's events.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="feature-item">
                  <i className="icon-present text-primary"></i>
                  <h3>Your data stays on your iOS device</h3>
                  <p className="text-muted">
                    <em>
                      Because of rising concern over privacy and data usage, the
                      children database exists on the device{" "}
                    </em>
                    <br></br>
                    <hr></hr>
                    <br></br>
                    When the user starts a new day, the database clears past
                    events.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="features" id="videos">
      <div className="container">
        <div className="section-heading text-center">
          <h2>Videos</h2>
          <p className="text-muted">Learn more about Look At Me - Daycare</p>
          <hr />
        </div>
        <div className="row">
          <div className="col-lg-12 my-auto">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6">
                  <div className="feature-item ">
                    <div className="video-container">
                      <iframe
                        src="https://www.youtube.com/embed/iIUp1Qiqoag"
                        frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen="true"
                        title="Add a Child"
                      ></iframe>
                    </div>

                    <i className="icon-screen-smartphone text-primary"></i>
                    <h3>Add A Child</h3>
                    <p className="text-muted">
                      Try for free with one child; add more children with your
                      subscription within the app.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="feature-item">
                    <div className="video-container">
                      <iframe
                        src="https://www.youtube.com/embed/IDuCmJaITuc"
                        frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen="true"
                        title="Todays events"
                      ></iframe>
                    </div>
                    <i className="icon-camera text-primary"></i>
                    <h3>Chart Today's Events</h3>
                    <p className="text-muted">
                      For each child document their activities
                    </p>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <div className="feature-item">
                    <div className="video-container">
                      <iframe
                        src="https://www.youtube.com/embed/3p8zfWJIHbk"
                        frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen="true"
                        title="Share"
                      ></iframe>
                    </div>
                    <i className="icon-present text-primary"></i>
                    <h3>Share</h3>
                    <p className="text-muted">
                      Share each child's developments as a PDF file, as is an
                      attachment to an email or a text message.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="feature-item">
                    <div className="video-container">
                      <iframe
                        src="https://www.youtube.com/embed/5j2Tgh_-6CQ"
                        frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen="true"
                        title="Settings"
                      ></iframe>
                    </div>
                    <i className="icon-lock-open text-primary"></i>
                    <h3>Settings</h3>
                    <p className="text-muted">
                      Learn more about the Setting within the app.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    {/* <section className="cta">
      <div className="cta-content">
        <div className="container">
          <h2>Easy Documentation!</h2>
          <Scroll type="id" element="download">
            <Link to="#download" className="btn btn-outline btn-xl">
              Start Today
            </Link>
          </Scroll>
        </div>
      </div>
      <div className="overlay"></div>
    </section> */}

    <section className="presskit bg-primary" id="presskit">
      <div className="container">
        <h2>Press Kit</h2>
        <p>Learn More!</p>
        
          <a href="/downloads/presskit.zip" className="btn btn-outline btn-xl">
            Download
          </a>
        
        {/* <Social /> */}
      </div>
    </section>

    <Footer />
  </Layout>
);

export default IndexPage;
